import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './NavBar.css';
import HomeNavv from '../../img/home.png';
import HomeNavvC from '../../img/homered.png';
import CursNav from '../../img/cursos.png';
import CursNavC from '../../img/cursosred.png';
import InstructorNav from '../../img/instructor.png';
import shorts from '../../img/shorts.png';
import shortsC from '../../img/shortsred.png';
import quizzNav from '../../img/quizz.png';
import perfilNav from '../../img/perperfil.png';
import perfilNavC from '../../img/perperfilred.png';
import logoHori from '../../img/logohorizontal.png';
import logoHoriC from '../../img/logohorizontalred.png';
import nosotrosNav from '../../img/cubo.png';
import nosotrosNavC from '../../img/cubored.png';


const NavBar = (({ setToken, nuevos, modo })=>{
	const cursoPrincipal = 11;
	const {pathname} = useLocation();
	function logOut(){
		setToken({});
	}
	return(
		<nav className="principal">
			<div className='divLo'>
				<img className='logoNav' src={modo=="oscuro" ? logoHori : logoHoriC}/>
			</div>
			<span>
				<NavLink to='/'>
					<img src={modo=="oscuro" ? HomeNavv : HomeNavvC}/>
					<span>Inicio</span>
				</NavLink>
			</span>
			<span>
				<NavLink to={'/cursos/'+cursoPrincipal+'/secciones'}
					className={ () => {
						return pathname.indexOf("/cursos") == 0 || pathname.indexOf("/video/") == 0 ? "active" : "" ;
					}}
				>
					<img src={modo=="oscuro" ? CursNav : CursNavC}/>
					<span>Cursos</span>
				</NavLink>
			</span>
			<span>
				<NavLink to='/tips'>
					{nuevos &&
						<div className='hayNuevo'>*</div>
					}
					<img src={modo=="oscuro" ? shorts : shortsC}/>
					<span>Tips</span>
				</NavLink>
			</span>
			<span>
				<NavLink to='/nosotros/'>
					<img src={modo=="oscuro" ? nosotrosNav : nosotrosNavC}/>
					<span>Nosotros</span>
				</NavLink>
			</span>
			<span>
				<NavLink to='/perfil/'>
					<img src={modo=="oscuro" ? perfilNav : perfilNavC}/>
					<span>Perfil</span>
				</NavLink>
			</span>
		</nav>
	);
})


export default NavBar;