import React, { useState, useEffect, useContext, useRef } from 'react';
import LoginContext from '../../components/Login/LoginContext';
import { Link, useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import './Inscribir.css';

const Inscribir = ()=>{
    const {id} = useParams();
    const navigate = useNavigate();
    const {setLogin, setToken, token} = useContext(LoginContext);
    const [precio, setPrecio] = useState()
    const [descuento, setDescuento] = useState({})
    const [curso, setCurso] = useState({});
    const [orden, setOrden] = useState("");
    const [inscrito, setInscrito] = useOutletContext();
    const codigo = useRef();
    useEffect(()=>{
        getData();
    }, [token])
    useEffect(()=>{
    }, [precio])
    useEffect(()=>{
        if(orden)
            verifica();
    },[orden])
    function verifica(){
        fetch("/cursos/clienteAPI/Suscribir/validar.php?orderId="+orden+"&token="+token)
        .then(response=> response.json())
        .then(json=>{
            if(json["errAuth"]){
                setToken({})
                return false;
            }
            if(json["status"]){
                setInscrito(true);
            }
            else{
                setTimeout(function(){
                    verifica();
                }, 5000)
            }
            //si es true ya quedó y redirigimos, si no, llamamos un settimeout
        });
    }
    async function getData(){
        const data = await fetch("/cursos/clienteAPI/Curso/get.php?id_curso="+id+"&token="+token)
        .then(response=> response.json())
        .then(json=>{
            if(json["errAuth"]){
                setToken({})
                return false;
            }
            return json;
        });
        if(data["id_curso"]){
            setCurso(data || {});
            if(!precio){
                if(data.id_descuento){
                    if(data.tipo=="Porcentaje"){
                        setPrecio(data.precio - (data.precio*data.descuento/100));
                    }
                    else{
                        setPrecio(data.precio - data.descuento);
                    }
                }
                else{
                    setPrecio(data.precio)
                }
            }
            setInscrito(data.inscrito!=0);
        }
        else{
            navigate('/cursos/');
        }
    }
    async function validarCodigo(){
        let code = codigo.current.value;
        fetch("/cursos/clienteAPI/Codigo/validate.php?id_curso="+id+"&codigo="+code+"&token="+token)
        .then(response=> response.json())
        .then(json=>{
            if(json["errAuth"]){
                setToken({})
                return false;
            }
            if(json.valido){
                setPrecio(json.precio);
                setCurso({...curso, descuento:json.descuento, id_descuento:json.id_descuento, tipo:json.tipo, descripcionD:json.descripcionD})
            }
            else{
                setPrecio(curso.precio);
                setCurso({...curso, descuento:"", id_descuento:"", tipo:"", descripcionD:""})
            }
            setDescuento(json);
        });
    }

	const initialOptions = {
	    "client-id": "AVl-FgpHhAXq2uM-y-Fj1NklCqoD4vrQJS0HiUFhZRLIxg-xMkxDsCBSymsgxeTIi2YFpgm61idbF-5A",
	    // "client-id": "AYeWKjBJ41pbIrSwdjMP1tCwQafwVdImxRyrGg_clecHD8r4HWmnr__pkOmbhC-0gTUSkk12Z5w3qVNa",
	    currency: "MXN",
	    intent: "capture",
	};
    if(inscrito){
        return(
        <>
            <div className='lvacio'>Ya estás inscrito a este curso.</div>
            <div className='botones'>
                <div className='sig' onClick={()=>{
                    navigate('/cursos/'+id+"/secciones/")
                }}>
                    Ir a Secciones
                </div>
            </div>
        </>
        )
    }
    if(!inscrito && curso.id_curso && precio)
    	return(
        <>
            {orden &&
                <div className='modal'>
                    <div>
                        <div>Espera un momento en lo que validamos tu pago, este proceso puede tardar unos minutos...</div>
                        <div className='imgCargando'></div>
                    </div>
                </div>
            }
        	<div className="contPay">
        		<div>
        			<h1>Estás a punto de mejorar tu vida. Con este increíble curso obtendrás resultados inigualables</h1>
        		</div>
                <div className='descPrecio'>
                    <div>
                        <div className='titTexto cen'>¿No cuentas con tarjeta bancaria?</div>
                        <div className='waEspecial'><a href='https://wa.me/525514510058' target='_BLANK' rel='noreferrer' to='/mensajes/'>¡Contacta a tu instructor!</a></div>
                        <div className="titTexto">Resumen</div>
                        <div className='desglose'>
                            {curso.id_descuento && (curso.codigo == "" || descuento.valido) &&
                                <>
                                    <div>
                                        <span>Precio:</span>
                                        <span className='tachar'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(curso.precio)}</span>
                                    </div>
                                    <div>
                                        <span>Descuento:</span>
                                        <span>{curso.tipo=="Porcentaje" ? curso.descuento+"%"+" ("+(new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(curso.precio*curso.descuento/100))+")" : new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(curso.descuento)}</span>
                                    </div>
                                    {curso?.descripcionD &&
                                        <div>{curso?.descripcionD}</div>
                                    }
                                    <div>
                                        <span>Precio Final:</span>
                                        <span>{curso.tipo=="Porcentaje" ? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(curso.precio - (curso.precio*curso.descuento/100)) : new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(curso.precio - curso.descuento)}</span>
                                    </div>
                                </>
                            }
                            {!curso.id_descuento &&
                                <div>
                                    <span>Precio Final:</span>
                                    <span>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(curso.precio)}</span>
                                </div>
                            }
                        </div>
                        <div className="titTexto">Código de descuento</div>
                        <div>
                            <input className="inputDon" placeholder="Ingresa tu cupón" type='text' ref={codigo}/>
                            {descuento.valido &&
                                <div className='error'>{descuento.razon}</div>
                            }
                            {!descuento.valido &&
                                <div className='error'>{descuento.razon}</div>
                            }
                            <div className='botones'>
                                <div className="sig" onClick={()=>{
                                    //validar cupon y aplicar descuento
                                    validarCodigo()
                                }}>Aplicar código</div>
                            </div>
                        </div>
                    </div>
            		<div className='botonPaypal'>
            			<PayPalScriptProvider options={initialOptions}>
            	            <BotonesPagar precio={precio} setOrden={setOrden}/>
            	        </PayPalScriptProvider>
            		</div>
                </div>
        	</div>
        </>
        );
}

const BotonesPagar = ({precio, setOrden})=>{
    const {id} = useParams();
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
	
    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
            },
        });
    }, [precio]);

    return (<>
            { isPending && <div className="spinner" /> }
            <PayPalButtons
                style={{ layout: "vertical" }}
                disabled={false}
                forceReRender={[precio]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    reference_id : id,
                                    amount: {
                                        currency_code: "MXN",
                                        value: precio,
                                    },
                                },
                            ],
                            application_context: {
		                      shipping_preference: "NO_SHIPPING"
		                    },
                        })
                        .then((orderId) => {
                            // Your code here after create the order
                            return orderId;
                        });
                }}
                onApprove={function (data, actions) {
                    return actions.order.capture().then(function () {
                        setOrden(data.orderID);
                    });
                }}
            />
        </>
    );
}

export default Inscribir;