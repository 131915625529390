import React, { useState, useEffect, useRef } from 'react';
import useToken from '../../useToken';
import { Link, useParams } from 'react-router-dom';
import VerFile from '../VerFile/VerFile';
import defaultCurso from '../../img/sinImagen.png';
import './cursos.css';

const Cursos = (()=>{
	const {token, setToken} = useToken();
	const [datos, setDatos] = useState([]);
	const {categoria} = useParams();
	const [buscar, setBuscar] = useState('');
	const [recomendadas, setRecomendadas] = useState([]);
	const [filtro, setFiltro] = useState('todos');
	const [modal, setModal] = useState(false);
	const nombre = useRef();
	const descripcion = useRef();
	const precio = useRef();
	useEffect(()=>{ 
		getData();
	}, [categoria])
	async function getData(){
		const data = await fetch("/cursos/clienteAPI/Curso/getAll.php?token="+token+"&categoria="+(categoria || ""))
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			console.log(json);
			return json;
		});
		setDatos(data.cursos || []);
		setRecomendadas(data.recomendadas || []);
	}
	return(
		<>
		<div className='buscador'>
			 
			<input 
			placeholder='Busca...'
			onChange={(e)=>{
				setBuscar(e.target.value);
			}}/>
		</div>
		<div className='botones'>
			<div className={filtro == "todos" ? "sig" : ""} onClick={()=>{
				setFiltro("todos") 
			}}>Todos los cursos</div>
			<div className={filtro == "inscrito" ? "sig" : ""} onClick={()=>{
				setFiltro("inscrito")
			}}>Mis cursos</div>
		</div>
		<div className='recomendadas'>
			{
				<Link className={!categoria ? 'selCat' : ''} onClick={()=>{
					document.body.onkeyup = undefined;}} 
                    to={'/cursos/'}  replace={!(!categoria)}>Todas</Link>
			}
			{recomendadas.map(cat=>{
                    return(
                        <Link className={categoria==cat.categoria ? 'selCat' : ''} onClick={()=>{
							document.body.onkeyup = undefined;}} 
                            to={'/cursos/categoria/'+cat?.categoria} key={cat?.id_categoria} replace={!(!categoria)}>{cat?.categoria}</Link>
                    );
                })
			}
		</div>
		<div className='cursos'>
			{
				datos.map((curso, i)=>{
					if((filtro=="inscrito" && curso.inscrito) || filtro=="todos")
						if(buscar=='' || curso.nombre.toLowerCase().includes(buscar.toLowerCase()))
						return(
							<Link to={'/cursos/'+curso.id_curso+"/secciones"} key={curso.id_curso}>
								<h2>{curso.nombre}</h2>
								<div className="imgCostos">
									<VerFile editar={false} imagen={{id_archivo:curso.id_archivo, ruta:curso.ruta}} defaultImg={defaultCurso} setImagen={false} tipo={"img"}/>
									<div>
										<span className="nameMobile">{curso.nombre}</span>
										{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(curso.precio)}
									</div>
								</div>
								<div className="contenidoCurso">
									
									<div>Videos <span>{curso.numVids || 0}</span></div>
									<div>Archivos <span>{curso.numFiles || 0}</span></div>
									{/*<div>Quizz <span>{curso.numCuest || 0}</span></div>*/}
								</div>
							</Link>
						);
				})
			}
		</div>
		</>
	);
})

export default Cursos;