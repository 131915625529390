import React from 'react';
import Testimonios from '../Testimonios/Testimonios';
import Instructor from '../Instructor/Instructor';
import './Nosotros.css';

const Nosotros = () => {
    return (
        <div className='nosotros'>
            <Instructor />
            <Testimonios />
        </div>
    );
};

export default Nosotros;