import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoginContext from '../../components/Login/LoginContext';
import cuestionario from '../../img/cuestionario.png';
import sinImgVideo from '../../img/sinImagen.png';
import like from '../../img/like.png';
import liked from '../../img/liked.png';
import save from '../../img/save.png';
import saved from '../../img/saved.png';
import teoria from '../../img/teoria.png';
import video from '../../img/video.png';


const Secciones = (({tipo})=>{
	const {id} = useParams();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [datos, setDatos] = useState([]);
	const [buscar, setBuscar] = useState('');
	const nombre = useRef();
	useEffect(()=>{
		getData();
	}, [])
	async function getData(){
		const data = await fetch("/cursos/clienteAPI/Lista/get.php?tipo="+tipo+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		setDatos(data || [])
	}
	return(
		<>
		<div className='buscador'>
			 
			<input 
			placeholder='Busca...'
			onChange={(e)=>{
				setBuscar(e.target.value);
			}}/>
		</div>
		<div>
			{datos.length == 0 &&
				<div className='lvacio'>Aún no has guardado algun elemento</div>
			}
		</div>
		<div className='contSecciones'>
			<div className='secciones'>
				{
					datos.map((elemento, i)=>{
						if(buscar=='' || elemento.nombre.toLowerCase().includes(buscar.toLowerCase()))
						return(
							<Lista elemento={elemento} key={elemento.tipo+"-"+elemento.id_elemento}/>
						);
					})
				}
			</div>
		</div>
		</>
	);
})

const Lista = ({elemento, inscrito=true})=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [progreso, setProgreso] = useState(elemento.progreso || 0);
	const [duracion, setDuracion] = useState(0);
	const [megusta, setMegusta] = useState(elemento.liked=='true');
	const [guardado, setGuardado] = useState(elemento.saved=='true');
	useEffect(()=>{
		if(duracion!=0 && progreso!=0){
		let porcentaje = Math.round(progreso*100/duracion);
		fetch("/cursos/clienteAPI/Videos/setProgreso.php?id_video="+elemento.id_elemento+"&progreso="+porcentaje+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			elemento.progreso = porcentaje;
		});	
		}
	}, [progreso])
	function meGusta(e){
		e.stopPropagation();
		if(megusta != elemento.liked)
		fetch("/cursos/clienteAPI/Videos/like.php?id_video="+elemento.id_elemento+"&like="+!megusta+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			elemento.liked = megusta;
			setMegusta(!megusta)
		});
	}
	function guardar(e){
		e.stopPropagation();
		if(guardado != elemento.saved)
		fetch("/cursos/clienteAPI/Videos/save.php?id_elemento="+elemento.id_elemento+"&tipo="+elemento.tipo+"&save="+!guardado+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			elemento.saved = guardado;
			setGuardado(!guardado)
		});
	}
	const navigate = useNavigate();
	const tiposImg = {
		"video": video,
		"cuestionario": cuestionario,
		"multi": teoria,
	}
	function handleProgreso(e, finished){
		if(finished){
			return setProgreso(e.target.duration);
		}
		if(e.target.currentTime - progreso > 30){
			setProgreso(e.target.currentTime);
		}
	}
	return (
		<>
		<div className='progresoElem' onClick={(e)=>{
			if(elemento.tipo == "video"){
				navigate('/video/'+elemento.id_elemento);
			}
			else if(elemento.tipo == "cuestionario"){
				navigate('/cuestionario/'+elemento.id_elemento);
			}
		}}>
		{inscrito && elemento.ruta && elemento.tipo == "video" &&
			<div className={'image'+(elemento.completado=='true' ? " completado" : "")}>
				{elemento.completado=="true" &&
					<div className='visto'>
						<div>{elemento.tipo=="video" ? "Visto" : "Contestado"}</div>
					</div>
				}
				<div className='relative'>
				<img src={elemento.rutaMini ? "/cursos/img/"+elemento.rutaMini : sinImgVideo}/>
				<div className='megusta' onClick={meGusta}>
					<img src={megusta ? liked : like}/>
				</div>
				</div>
				{elemento.completado!="true" &&
					<progress value={elemento.progreso/100}></progress>
				}
			</div>
		}
		{(!inscrito || !(elemento.ruta && elemento.tipo=="video")) && 
			<div className={'image'+(elemento.completado=="true" ? " completado" : "")}>
				{elemento.completado=="true" &&
					<div className='visto'>
						<div>{elemento.tipo=="video" ? "Visto" : "Contestado"}</div>
					</div>
				}
				<img src={tiposImg[elemento.tipo]}/>
			</div>
		}
			<div className='descElem'>
				<div><b>{elemento.nombre}</b></div>
				<div>{elemento.descripcion}</div>
			</div>
			<div className='guardar' onClick={guardar}>
				<img src={guardado ? saved : save}/>
			</div>
		</div>
		</>
	);
}

export default Secciones;