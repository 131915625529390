import React, { useState, useEffect, useRef } from 'react';
import useToken from '../../useToken';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import SubirFile from '../SubirFile/SubirFile';
import './Shorts.css';
import './carousel.css';

import Video from '../../img/video.png';
import liked from '../../img/liked.png';
import saved from '../../img/saved.png';
import Guardar from '../../img/guardar.png';
import Cancelar from '../../img/cancelar.png';

const CarouselShorts = (()=>{
	const navigate = useNavigate();
	const {token, setToken} = useToken();
	const [datos, setDatos] = useState([]);
	const [buscar, setBuscar] = useState('');
	useEffect(()=>{
		getData();
	}, [])
	
	async function getData(){
		const data = await fetch("http://localhost/cursos/clienteAPI/Shorts/getAll.php?token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		console.log(data);
		setDatos(data || []);
	}
	return(
		<>
		<div className='contSlider'>
			<div className='subTips'>Aprende Más con Menos, Tips de Excel en Imágenes</div>
			<Carousel axis="vertical" swipeable={true} emulateTouch={true} showArrows={true} showThumbs={false} showStatus={false} showIndicators={false} infiniteLoop={true}>
				{datos?.shorts?.slice(0,5)?.map((short, index)=>{
					return(
						<div key={index} className='short'>
							<div className='video'>
								{short.tipo=="video" &&
									<video src={short.ruta.substring(0,5) == "https" ? short.ruta : '/cursos/video/'+short.ruta+"#t=0.001"} controls/>
								}
								{short.tipo=="imagen" &&
									<img src={short.ruta}/>
								}
							</div>
							<div className='info'>
								<h3 className='titulo'>{short.nombre}</h3>
								<div className='descripcion'>{short.descripcion}</div>
							</div>
						</div>
					)
				})}
			</Carousel>
			<Link to='/tips/todos' className='verMas'>Ver más</Link>
		</div>
		</>
	);
})

export default CarouselShorts;