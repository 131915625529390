import React, { useState, useEffect, useRef, useContext } from 'react';
import LoginContext from '../../components/Login/LoginContext';
import { useParams, useNavigate, Link } from 'react-router-dom';

import Ganadas from '../Ganadas/Ganadas';

import './Live.css'

import cuestionario from '../../img/cuestionario.png';
import video from '../../img/video.png'; 
import teoria from '../../img/teoria.png';
import completo from '../../img/completo.png';
import incompleto from '../../img/incompleto.png';
import like from '../../img/like.png';
import liked from '../../img/liked.png';
import save from '../../img/save.png';
import saved from '../../img/saved.png';

const Live = ()=>{
	const {id} = useParams();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [datos, setDatos] = useState({});

	useEffect(()=>{
		getData();
	}, [])
	async function getData(){
		const data = await fetch("/cursos/clienteAPI/Lives/get.php?id_live="+id+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		setDatos(data || [])
	}
	return(
		<div className='contLive'>
			{datos?.url &&
				<div className='contFrame'>
					<iframe src={"https://www.youtube.com/embed/"+datos.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				</div>
			}
			<div className='datosLive'>
				<div>{datos.nombre}</div>
				<div>{datos.descripcion}</div>
			</div>
		</div>
	);
}

export default Live;