import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    try{
      const userToken = JSON.parse(tokenString);
      return userToken?.token || ""
    }
    catch(e){
      return "";
    }
  };
  const getEstado = () => {
    const tokenString = localStorage.getItem('token');
    try{
      const userToken = JSON.parse(tokenString);
      return userToken?.estado
    }
    catch(e){
      return "";
    }
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    if(!userToken.token){
      let oldToken = getToken();
      if(oldToken && !oldToken?.estado){
        if(oldToken?.token && !userToken.estado){
          alert("Tu sesión ha expirado, por favor inicia sesión nuevamente.")
          userToken.estado='errAuth';
        }
        localStorage.setItem('token', JSON.stringify(userToken));
        if(userToken.estado && userToken.estado=="cerrar")
          window.location.href='/app';
        else  
          window.location.reload();
      }
    }
    else{
      localStorage.setItem('token', JSON.stringify(userToken));
      setToken(userToken.token);
    }
  };

  return {
    setToken: saveToken,
    token
  }
}