import React, { useState, useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import LoginContext from '../../components/Login/LoginContext';
import useToken from '../../useToken';
import { Link } from 'react-router-dom';
import './Inicio.css';

import bienv from '../../img/pablo.png';
import testi from '../../img/testimonio.png';
import grupoW from '../../img/grupoWA.png';
import grupoF from '../../img/grupoFB.png';

import imgLive from '../../img/live2.png';
import verNuevo from '../../img/vernuevo.png';
import sinImg from '../../img/sinImagen.png';
import sinImgVideo from '../../img/sinImagen.png';
import flecha from '../../img/volver.png'; 
import flecha2 from '../../img/volverRed.png';
import wa from '../../img/waInicio.png';
import coca from '../../img/coca1.png';

import quienes from '../../img/quienes.png';
import consul from '../../img/consulto.png';
/*Logos*/

import atecsa from '../../img/atec.png';
import ma from '../../img/ma.png';
import cdo from '../../img/cdo.png';
import imeg from '../../img/imeg.png';
import aadi from '../../img/aadi.png';
import toledo from '../../img/toledo.png';
import sinhuella from '../../img/sinhuella.png';

import wats from '../../img/wa.png';
import face from '../../img/fb.png';
import yout from '../../img/you.png';
import insta from '../../img/ins.png';

const Inicio = (({setNuevos})=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [datos, setDatos] = useState([]);
	const [buscar, setBuscar] = useState('');
	const [modal, setModal] = useState(false);
	const nombre = useRef();
	const descripcion = useRef();
	const precio = useRef();
	useEffect(()=>{
		getData();
	}, [token])
	async function getData(){
		const data = await fetch("/cursos/clienteAPI/inicio.php?token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		setDatos(data || []);
		setNuevos(data.nuevos);
	}
	return(
		<div>
			{/*<div className='topInicio'>
				<div>	
					<div className='testiDiv'>
						<NavLink to='/testimonios'><img className='iniIMG' src={testi}/>
							<span className="botbotText bEspecial">
								<span>Más Testimonios</span>
								<span><img src={flecha}/></span>
							</span>
						</NavLink>
					</div>
					<div className='coloNuevo'>
						<div>
							<NavLink to='/bienvenida'><img className='iniIMG' src={bienv}/><span className="botbotText bEspecial" ><span>¡Bienvenido!</span><span><img src={flecha}/></span></span></NavLink>
						</div>
						<div>
							<a href='https://wa.me/525514510058' target='_BLANK' rel='noreferrer' to='/mensajes/'>
								<img className='iniIMG' src={wa}/><span className="botbotText bEspecial" ><span>Contáctame</span><span><img src={flecha}/></span></span>
							</a>
						</div>
					</div>
					<div className='coloNuevo'>
						<div>
							<a href='https://chat.whatsapp.com/BkY0stJ03ED0UFfZxUfYOs' target='_BLANK' rel='noreferrer' to='/mensajes/'>
								<img className='iniIMG noBox' src={grupoW}/><span className="botbotText bEspecial" ><span>Grupo WhatsApp</span><span><img src={flecha}/></span></span>
							</a></div>
						<div>
							<a href='https://www.facebook.com/groups/503545231011339/?ref=share_group_link' target='_BLANK' rel='noreferrer' to='/mensajes/'>
								<img className='iniIMG noBox' src={grupoF}/><span className="botbotText bEspecial" ><span>Grupo Facebook</span><span><img src={flecha}/></span></span>
							</a>
						</div>
					</div>
				</div>
			</div>*/}
			<div className='bloques'>
				<Lives data={datos.lives || []} bienvenida={datos?.bienvenida}/>
				<Bloque data={datos.misCursos || []} tipo="curso" titulo="Mis Cursos"/>
				<Bloque data={datos.populares || []} tipo="curso" titulo="Más Populares"/>
				<Bloque data={datos.continuar || []} tipo="video" titulo="Continuar Viendo"/>
				<Bloque data={datos.volver || []} tipo="video" titulo="Ver de nuevo"/>
			</div>
		</div>
	);
})

const Lives = ({data, bienvenida})=>{
	const [mostrar, setMostrar] = useState(false);
	// console.log(bienvenida.ruta)
	return(
		<div className='bloque'>
				<div>
					<div className="redes rInicio">
						<a href='https://wa.me/525532497143' target='_BLANK' rel='noreferrer' to='/mensajes/'>
							<img src={wats}/>
						</a>
						<a href='https://www.facebook.com/profile.php?id=61553769872047' target="_blank">
							<img src={face}/>
						</a>
						<a href='https://www.tiktok.com/@data_mast3r?_t=8hX998LopqX&_r=1' target="_blank">
							<img src={yout}/>
						</a>
						<a href='https://instagram.com/data_mast3r?utm_source=qr' target="_blank">
							<img src={insta}/>
						</a>
					</div>
				</div>
			<div className='quienes'>
				<div className='texto'>
					<div className='typing titQuienes auxN'>¿Quienes somos?</div>
					<div>
						<p>Data(Mast3r) es una plataforma y app móvil desarrollada en enero del 2024 que brinda cursos y lecciones para aprender y mejorar tus habilidades en el uso de Microsoft Excel y el análisis de datos. Esta plataforma ofrece una gran variedad de cursos, desde lo más básico hasta lo avanzado y abarca temas como fórmulas y funciones, tablas dinámicas, gráficos, tableros de control, KPI´s, complementos de Excel, macros, importar y limpiar datos, realizar análisis estadísticos, crear visualizaciones interactivas y generar informes personalizados y mucho más. Además, cuenta con ejercicios prácticos y aplicados a la vida real, con evaluaciones y certificaciones para que el alumno pueda demostrar sus conocimientos adquiridos.</p>
						<p>Esta plataforma tiene un método de enseñanza diferente a los que se tiene en el mercado actualmente ya que cuenta con clases amenas y divertida con el uso de memes enfocándose principalmente en la parte práctica de los ejercicios y sus respectivas soluciones.</p>
						<p>Esta plataforma es ideal tanto para principiantes que desean aprender desde cero como para profesionales que buscan perfeccionar sus habilidades en el análisis de información por medio de Excel.</p>
						<p>Contamos con cursos para empresas con la modalidad presencial en CDMX y Estado de México y por zoom para el resto de la república mexicana.</p>
					</div>
				</div>
				<div className='vidInicio'>
					<div className='typing titQuienes auxDis'>¿Quienes somos?</div>
					{bienvenida &&
						<video controls autoPlay muted loop className='videoBienvenida'>
							<source src={bienvenida?.ruta} type="video/mp4"/>
						</video>
					}
					{!bienvenida &&
						<img src={quienes}/>
					}
				</div>
			</div>
			<div className='quienes quiAux'>
				<div className='texto'>
					<div className='typing titQuienes auxN'><div>Consultoría, proyectos y automatizaciones</div></div>
					<div>
						<p>Esta plataforma es ideal tanto para principiantes que desean aprender desde cero como para profesionales que buscan perfeccionar sus habilidades en el análisis de información por medio de Excel.</p>
						<p>Si quieres ver el antes y después de tomar una capacitación con nosotros, optimizar procesos, generar métricas que impacten en tus análisis de resultados y hacer fórmulas complejas, no dudes en ponerte en contacto con nosotros.</p>
					</div>
				</div>
				<div>
					<div className='typing titQuienes auxDis'>Consultoría, proyectos y automatizaciones</div>
					<img src={consul}/>
				</div>
			</div>
			<div className='colabo'>
				<div className='titUno'>Empresas que han confiado en nosotros, ¡Conoce sus servicios!</div>
				<div>
					<div><a href="https://atecsamx.com/" target="_blank"><img src={atecsa}/></a></div>
					<div><a href="https://maintegradora.com/" target="_blank"><img src={ma}/></a></div>
					<div><a href="https://cdo.com.mx/" target="_blank"><img src={cdo}/></a></div>
					<div><a href="https://www.migsa.mx/" target="_blank"><img src={imeg}/></a></div>
					<div><a><img src={aadi}/></a></div>
					<div><a><img src={toledo}/></a></div>
					<div><a><img src={sinhuella}/></a></div>
				</div>
			</div>
			<div className='tituloSeccion'>
				<span>Próximos Lives</span>
				<span className="botbotText" onClick={()=>{setMostrar(!mostrar)}}>{data.length > 4 &&
					<>
						<span>{mostrar ? "Ver menos" : "Ver más"}</span>
						<span><img src={flecha2}/></span>
					</>
				}	
				</span>
			</div>
			<div className='lives'>
				{
					data.map(live=>{
						return(
							<Link to={'/lives/'+live.id_live} className='live' key={live.id_live}>
								<div className='divImg'><img src={live.miniatura}/></div>
								<div className='nameLive'>{live.nombre}</div>
								<div className='titFH'>Fecha y hora</div>
								<div className='fhoraLive'>
									<span>{live.fecha}</span>
									<span>{live.hora}</span>
								</div>
							</Link>
						)
					})
				}
			</div>
		</div>
	);
}

const Bloque = ({data, tipo, titulo})=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [mostrar, setMostrar] = useState(false);
	return(
		<div className='bloque'>
		<div className='tituloSeccion'>
			<span>{titulo}</span>
			<span className="botbotText" onClick={()=>{setMostrar(!mostrar)}}>{data.length > 4 &&
				<>
					<span>{mostrar ? "Ver menos" : "Ver más"}</span>
					<span><img src={flecha2}/></span>
				</>
			}	
			</span>
		</div>
			<div className='contBloque'>
			{data.length == 0 && token == "" &&
				<div className='nosesion' onClick={()=>{
					setLogin(true);
				}}>
					<div>Inicia sesión para acceder a todas las funciones</div>
					<div className='botones'>
						<Link className="sig" to='/login'>
							Iniciar sesión
						</Link>
					</div>
				</div>
			}
			{
				(mostrar ? data : data.slice(0, 4)).map((x, i)=>{
					return(
					<Link to={tipo=="curso" ? '/cursos/'+x.id_curso : '/video/'+x.id_multimedia} key={tipo+i}>
						<div className='divImg noRadius'>
						{tipo !="video" &&
							<img src={x.ruta ? "/cursos/img/"+x.ruta : sinImg}/>
						}
						{tipo =="video" &&
						<>
							<img src={x.rutaMini ? "/cursos/img/"+x.rutaMini : sinImgVideo}/>
							{x.progreso &&
								<progress value={x.progreso/100}></progress>
							}
						</>
						}
						</div>
						<div>{x.nombre}</div>
					</Link>);
				})
			}
			</div>
		</div>
	);
}

export default Inicio;