import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Notificacion.css';
import defaultLogo from '../../img/logosavage.png';

const Notificacion = (({notificacion, setShow})=>{
	const navigate = useNavigate();
	return(
		<>
			<div className='notifica' onClick={(e)=>{
				if(e.target===e.currentTarget){
					//redirigir
					if(notificacion?.data?.url){
						let url = notificacion?.data?.url.replace("https://www.datamast3r.com", "")
						navigate(url);
					}
					setShow(false);
				}
			}}>
				<div className='notImg'>
					<img src={notificacion?.data?.img || defaultLogo}/>
				</div>
				<div className='notMsg'>
					<div>{notificacion.notification.title}</div>
					<div>{notificacion.notification.body}</div>
				</div>
			</div>
		</>
	);
})

export default Notificacion; 