import React, { useState, useEffect, useRef } from 'react';
import './Alert.css';

const Alert = (({alert, setAlert})=>{
	return(
		<>
			<div className='modal' onClick={(e)=>{
				if(e.target===e.currentTarget){
					setAlert({...alert, ver:false});
				}
			}}>
				<div className='alert'>
					<div className='alertMsg'>{alert.mensaje}</div>
					<div className='botones unomas'>
						<div className='sig' onClick={()=>{
							if(alert.confirm)
								alert.funcion()
							setAlert({...alert, ver:false});
						}}>Aceptar</div>
						{alert.confirm &&
							<div className='cancel' onClick={()=>{
								setAlert({...alert, ver:false});
							}}>Cancelar</div>
						}
					</div>
				</div>
			</div>
		</>
	);
})

export default Alert; 