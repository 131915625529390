import React, { useState, useEffect, useRef, useContext } from 'react';
import LoginContext from '../../components/Login/LoginContext';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import './Shorts.css';

import Video from '../../img/video.png';
import like from '../../img/like.png';
import liked from '../../img/liked.png';
import saveWhite from '../../img/saveWhite.png';
import saved from '../../img/saved.png';
import flecha from '../../img/volver.png'; 
import flecha2 from '../../img/volverRed.png';

const Shorts = (({tipo="", setNuevos})=>{
	const {categoria} = useParams();
	const navigate = useNavigate();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [datos, setDatos] = useState([]);
	const [recomendadas, setRecomendadas] = useState([]);
	const [more, setMore] = useState(false);
	const [page, setPage] = useState(0);
	const [buscar, setBuscar] = useState('');
	useEffect(()=>{
		getData();
	}, [categoria])
	useEffect(()=>{
		if(page!=0)
			getData(true);
	},[page])
	useEffect(()=>{
		getData();
	}, [buscar])
	async function getData(concat){
		const data = await fetch("/cursos/clienteAPI/Shorts/getAll.php?token="+token+"&tipo="+tipo+"&categoria="+(categoria || "")+"&page="+page+"&buscar="+buscar)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		if(tipo=="")
			setNuevos(false);
		setRecomendadas(data.recomendadas || []);
		if(concat)
			setDatos(datos.concat(data["shorts"].splice(0,10)));
		else
			setDatos(data["shorts"].splice(0,10));
		setMore(data["final"] ? false : true)
	}
	return(
		<>
		<div className='buscador'>
			<input 
			placeholder='Busca...'
			onChange={(e)=>{
				setPage(0)
				setBuscar(e.target.value);
			}}/>
		</div>
		<div className='recomendadas'>
			{recomendadas.map(cat=>{
                    return(
                        <Link onClick={()=>{
							setPage(0);
                            document.body.onkeyup = undefined;}} 
                            to={'/tips/categoria/'+cat.categoria} key={cat.id_categoria} replace={!(!categoria)}>{cat.categoria}</Link>
                    );
                })
			}
		</div>
		{more &&
			<div className='tituloSeccion shortEspecial'>
				<span>Shorts</span>
				<span className='botbotText'>
					<span className='sig' onClick={()=>{
						setPage(page+1);
					}}>Ver más</span>
					<span><img src={flecha2}/></span>
				</span>
			</div>
		}
		{!more &&
			<div className='tituloSeccion shortEspecial'><span>Shorts</span></div>
		}
		{datos.length == 0 &&
			<div className='lvacio'>No hay shorts disponibles</div>
		}
		<div className='shorts'>
			{
				datos.map((short, i)=>{
					return(
						<MiniShort key={short.id_short} short={short} datos={datos} categoria={categoria}/>
					);
				})
			}
		</div>
		<Outlet context={[datos, setDatos, categoria, setPage, more]}/>
		</>
	);
})

const MiniShort = ({short, datos, categoria})=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [megusta, setMegusta] = useState(short.liked=='true');
	const [guardado, setGuardado] = useState(short.saved=='true');
	useEffect(()=>{
		setMegusta(short.liked=='true');
		setGuardado(short.saved=='true');
	}, [datos])
	function meGusta(e){
		e.preventDefault();
        e.stopPropagation();
        fetch("/cursos/clienteAPI/Shorts/like.php?id_short="+short.id_short+"&like="+!megusta+"&token="+token)
        .then(response=> response.json())
        .then(json=>{
            if(json["errAuth"]){
                setToken({})
                return false;
            }
            short.liked = megusta;
            setMegusta(!megusta)
        });
    }
    function guardar(e){
    	e.preventDefault();
        e.stopPropagation();
        fetch("/cursos/clienteAPI/Shorts/save.php?id_short="+short.id_short+"&save="+!guardado+"&token="+token)
        .then(response=> response.json())
        .then(json=>{
            if(json["errAuth"]){
                setToken({})
                return false;
            }
            short.saved = guardado;
            setGuardado(!guardado)
        });
    }

	function esNuevo(){
		const dias = 20;
		let time1 = new Date(short.fecha).getTime();
		let time2 = new Date().getTime();
		return time2 - dias*86400000 < time1;
	}
	const nuevo = esNuevo();
	return(
		<div>
			<Link to={categoria ? '/tips/categoria/'+categoria+'/'+short.id_short:'/tips/todos/'+short.id_short}>
				<div className='contPrev'>
				{nuevo &&
					<div className='new'>¡Nuevo!</div>
				}
				{short.tipo == "video" &&
					<video src={short.ruta.substring(0,5) == "https" ? short.ruta : '/cursos/video/'+short.ruta+"#t=0.001"} muted playsInline onClick={(e)=>{
						e.preventDefault();
						e.currentTarget.parentNode.click();
					}}></video>
				}
				{short.tipo == "imagen" &&
					<img src={short.ruta.substring(0,5) == "https" ? short.ruta : '/cursos/imagenes/'+short.ruta} alt=""/>

				}
					<div className='miniVistas'>
						<img src={Video}/>
						<span>{short.vistas}</span>
					</div>
					{token!="" &&
						<div className='accionesShort'>
							<div className='megustaShort' onClick={meGusta}>
								<div className='contMini'>
									<img src={megusta ? liked : like}/>
								</div>
							</div>
							<div className='guardarShort' onClick={guardar}>
								<div className='contMini'>
									<img src={guardado ? saved : saveWhite}/>
								</div>
							</div>
						</div>
					}
				</div>
				<div className='nomShort'>{short.nombre}</div>
			</Link>
		</div>
	);
}
export default Shorts;