import React, { useContext, useEffect, useRef, useState } from 'react';
import LoginContext from '../../components/Login/LoginContext';
import defaultPerfil from '../../img/sinfoto.png';
import verificado from '../../img/verificado.png';
import './Comentarios.css';

const Comentarios = ({id_video})=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
    const [comentarios, setComentarios] = useState([]);
    
    useEffect(()=>{
		getData();
    },[])
    async function getData(){
		const data = await fetch("/cursos/clienteAPI/Comentario/getAll.php?id_video="+id_video+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
            console.log(json);
			return json;
		});
		console.log(data);
		setComentarios(data.comentarios || []);
	}
    return(<>
        <Comentar id_video={id_video} setComentarios={setComentarios}/>
        <div className='contComentarios' >
            {comentarios.map(comentario=>{
                return(
                    <Caja key={comentario.id_comentario} comentario={comentario} id_video={id_video} setComentarios={setComentarios}/>
                )
            })
            }
        </div>
    </>)
}
const Caja = ({comentario, id_video, respuesta})=>{
    const [responder, setResponder] = useState(false);
    const [comentarios, setComentarios] = useState(comentario?.respuestas || []);
    return (<div>
        <Comentario comentario={comentario} setResponder={setResponder} respuesta={respuesta}/>
        <div className='comRespuestas'>
            {comentarios?.map(respuesta=>{
                return(
                    <Caja key={respuesta.id_comentario} comentario={respuesta} respuesta={true}/>
                )
            })
            }
        </div>
        {responder &&
            <Comentar id_comentario={comentario?.id_comentario} id_video={id_video} setComentarios={setComentarios} respuesta={true}/>
        }
    </div>)
}
const Comentario = ({comentario, respuesta, setResponder})=>{
    const {setLogin, setToken, token} = useContext(LoginContext);
    const [likes, setLikes] = useState(comentario?.likes*1 || 0);
    const [liked, setLiked] = useState(comentario.liked);
    function like(){
        fetch("/cursos/clienteAPI/Comentario/like.php?id_comentario="+comentario.id_comentario+"&like="+(!liked)+"&token="+token)
        .then(response=>response.json())
        .then(json=>{
            console.log(json);
            if(!liked){
                setLikes(likes + 1);
            }
            else{
                setLikes(likes - 1);
            }
            setLiked(!liked);
        })
    }
    return(<div className={'comentario'+(comentario.status=='true' ? "" : " borrado")}>
        <div className='miniImg'>
            <img src={comentario.ruta ? comentario.id_usuario > 0 ? '/cursos/clienteAPI/img/'+comentario.ruta : '/cursos/img/'+comentario.ruta : defaultPerfil}/>
        </div>
        <div>
            <div className='nameVer'>
                <b>{comentario.nombre}</b>
                <i>@{comentario.usuario}</i>
                {!comentario.id_usuario &&
                    <img src={verificado} className='verified'/>
                }
            </div>
            <div className='mainComentario'>{comentario?.comentario}</div>
            <div className='opciones'>
                <span>
                    <span>{likes > 0 ? likes : ""}</span>
                    <span onClick={like} className={liked ? "liked" : ""}>Me gusta</span>
                </span>
                {!respuesta &&
                    <span onClick={()=>{
                        setResponder(true);
                    }}>Responder</span>
                }
                <span>{comentario.fecha}</span>
            </div>
        </div>
    </div>)
}

const Comentar = ({id_comentario, id_video, setComentarios, respuesta})=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
    const [disabled, setDisabled] = useState(false);
    const comentario = useRef();
    function guardar(){
        if(disabled)
            return false;
        setDisabled(true);
        if(!comentario.current.reportValidity())
            return false;
		let data = {
            id_video : id_video,
            id_respuesta : id_comentario,
            comentario : comentario.current.value,
		};
        console.log(data);
		fetch("/cursos/clienteAPI/Comentario/set.php?token="+token, {
	      method: 'POST',
	      mode: 'cors',
	      headers: {
	        Accept: 'application/json',
	        'Content-Type': 'application/json',
	        'Access-Control-Allow-Origin':'*'
	      },
	      body: JSON.stringify(data)
	    })
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
            data["id_usuario"] = json["id_usuario"];
            data["usuario"] = json["usuario"];
            data["nombre"] = json["nombre"];
            data["ruta"] = json["ruta"];
            data["id_comentario"] = json["id_comentario"];
            data["fecha"] = json["fecha"];
            setComentarios(comentarios=>{
                if(respuesta){
                    console.log([...comentarios, data])
                    return [...comentarios, data];
                }
                else{
                    console.log([data, ...comentarios])
                    return [data, ...comentarios];
                }
            })
            comentario.current.value="";
            setDisabled(false);
		})
        .catch(e=>{
            console.log(e);
            setDisabled(false);
        })
	}
    return (
    <div className='todComen'>
        <textarea className='inputDon' placeholder="Añade tu comentario..." ref={comentario} required></textarea>
        <div className='botones'><div className={'sig'+(disabled ? ' disabled' : '')} onClick={guardar}>Comentar</div></div>
    </div>);
}

export default Comentarios;