import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import LoginContext from '../../components/Login/LoginContext';
import './Instructor.css';
import sinFoto from '../../img/sinfoto.png';
import Pablo from '../../img/pabloBio.png';
import volver from '../../img/volverRed.png';

import wa from '../../img/wa.png';
import fb from '../../img/fb.png';
import YT from '../../img/you.png';
import insta from '../../img/ins.png';

const Instructor = ()=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [perfil, setPerfil] = useState({});
	
	useEffect(()=>{
		getData();
	}, [])

	async function getData(){
		const data = await fetch("/cursos/clienteAPI/Instructor/get.php?token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		setPerfil(data || {});
	}
	return (
		<div className='perfil'>

			<div className='nom'>{perfil.nombre}</div>
			<div className='informacion infoInstructor'>
				{/*<div className='pfp'>
					<div>
						<div className='divImg'>
							<img src={perfil.ruta ? "/cursos/img/"+perfil.ruta : sinFoto}/>
						</div>
					</div>
				</div>*/}
				<div className="fotoBio">
					<div><img src={Pablo}/></div>
				</div>
				<div>
					<div className="mensa" >
						<Link className="botbotText" to='/mensajes/'>
							<span><span>{perfil.noMensajes}</span> Mensajes</span>
							<span><img src={volver}/></span>
						</Link>
					</div>
					<div className='bio'>{perfil.biografia}</div>
					<div className="redes">
						<a href='https://wa.me/525532497143' target='_BLANK' rel='noreferrer' to='/mensajes/'>
							<img src={wa}/>
						</a>
						<a href='https://www.facebook.com/profile.php?id=61553769872047' target="_blank">
							<img src={fb}/>
						</a>
						<a href='https://www.tiktok.com/@data_mast3r?_t=8hX998LopqX&_r=1' target="_blank">
							<img src={YT}/>
						</a>
						<a href='https://instagram.com/data_mast3r?utm_source=qr' target="_blank">
							<img src={insta}/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Instructor;