import React, { useState, useEffect, useContext } from 'react';
import LoginContext from '../../components/Login/LoginContext';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Ganadas from '../Ganadas/Ganadas';
import VerFile from '../VerFile/VerFile';

import cuestionarioImg from '../../img/cuestionario.png';
import borrarImg from '../../img/borrar.png';
import gif from '../../img/fiesta.gif';

import './Cuestionario.css';

const Cuestionario = (()=>{
	const id_especial = 10;
	const {id} = useParams();
	const navigate = useNavigate();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [cuestionario, setCuestionario] = useState({});
	const [correctas, setCorrectas] = useState(0);
	const [modal, setModal] = useState(false);
	const [indice, setIndice] = useState(-1);
	const [tiempo, setTiempo] = useState(0);
	const [insignias, setInsignias] = useState([]);
	useEffect(()=>{
		getData();
	}, [token])
	async function getData(){
		const data = await fetch("/cursos/clienteAPI/Cuestionario/get.php?id_cuestionario="+id+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		console.log(data);
		if((!data.inscrito && data.id_curso!=id_especial) || (data.id_video && data.videoTerminado!="true")){
			navigate(-1);
		}
		setCuestionario(data || []);
	}
	return(
	<>
		{modal &&
			<div className='modal modalCuest' onClick={(e)=>{
				if(e.target===e.currentTarget){
					setModal(false);
				}
			}}>
				<div>
					<h1>¡Felicidades!</h1>
					<div className='gifs'>
						<div><img src={gif}/></div>
						<div><img className='volteaGif' src={gif}/></div>
					</div>
					<h3>Completaste el cuestionario.</h3>
					<div className='botones'>
						<div className='cancel' onClick={()=>{
							setModal(false);
						}}>Cerrar</div>
					</div>
				</div>
			</div>
		}
		<Ganadas insignias={insignias} setInsignias={setInsignias}/>
		<div className='cuestionario'>
			<div>
				<div className="contPreguntas preguntasQuiz">
				{indice == -2 &&
					<>
						<div className="datosCuest">
							<div>
								<span>Cuestionario {cuestionario.orden}</span>
							</div>
							<div className='divImg'>
								<img src={cuestionario.ruta ? "/cursos/img/"+cuestionario.ruta : cuestionarioImg}/>
							</div>
							<div>
								<h1>{cuestionario.nombre}</h1>
								<h2>{cuestionario.descripcion}</h2>
							</div>
							<div>Preguntas Correctas: {correctas+" de "+cuestionario?.preguntas?.length || 0}</div>
							{(cuestionario.completado=='true' || correctas==cuestionario?.preguntas?.length) &&
								<>
									<div className='completado feliz'><span>¡Felicidades!</span><span>Completaste el cuestionario.</span></div>
									<div className="botonsigL" onClick={()=>{
										navigate(-1);
									}}>Regresar</div>
								</>
							}
							{correctas!=cuestionario?.preguntas?.length &&
								<div className='botones'>
									<div className='sig' onClick={()=>{
										cuestionario.preguntas.forEach(pregunta=>{
											pregunta.respuestas.forEach(respuesta=>{
												respuesta.correcta = false;
											})
										})
										setIndice(-1);
									}}>Intentar de nuevo</div>
								</div>
							}
						</div>
					</>

				}
				{indice == -1 &&
					<>
						<div className="datosCuest">
							<div>
								<span>Cuestionario {cuestionario.orden}</span>
							</div>
							{cuestionario.completado=='true' &&
								<div className='completado'>
									Ya has completado este cuestionario, sin embargo puedes volver a contestarlo para afirmar tus conocimientos.
								</div>
							}
							<div className='divImg'>
								<img src={cuestionario.ruta ? "/cursos/img/"+cuestionario.ruta : cuestionarioImg}/>
							</div>
							<div>
								<h1>{cuestionario.nombre}</h1>
								<h2>{cuestionario.descripcion}</h2>
							</div>
							<div>Número de Preguntas: {cuestionario?.preguntas?.length || 0}</div>
							<div className='botones'>
								<div className='sig' onClick={()=>{
									setTiempo(new Date());
									setIndice(0);
								}}>Empezar</div>
							</div>
						</div>
					</>

				}
				{
					cuestionario.preguntas?.map((pregunta, i)=>{
						pregunta.orden = i+1;
						if(i == indice || indice==-2)
							return(
								<Question pregunta={pregunta} preguntas={cuestionario.preguntas} indice={indice} setIndice={setIndice} tiempo={tiempo} setCorrectas={setCorrectas} key={pregunta.id_pregunta} setInsignias={setInsignias} setModal={setModal}/>
							);
						else{
						}
					})
				}
				</div>
			</div>
		</div>
	</>
	);
})

const Question = (({pregunta, preguntas, indice, setIndice, tiempo, setCorrectas, setInsignias, setModal})=>{
	const {id} = useParams();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [imagen, setImagen] = useState({id_archivo:pregunta.id_archivo, ruta:pregunta.ruta})
	const [error, setError] = useState("");
	function terminar(){
		let tiempoF = new Date();
		let ms = (tiempoF-tiempo)/1000;
		let respuestasFinales = [];
		preguntas.forEach(pregunta=>{
			pregunta.respuestas.forEach(respuesta=>{
				if(respuesta.correcta){
					respuestasFinales.push({"id_pregunta" : pregunta.id_pregunta, "id_respuesta" : respuesta.id_respuesta})
				}
			})
		})
		let data = {id_cuestionario: id, respuestas: respuestasFinales, tiempo: ms}
		fetch('/cursos/clienteAPI/Cuestionario/contestar.php?token='+token, {
		  method: 'POST',
		  body: JSON.stringify(data)
		}).then((response) => {/*console.log(response.text());*/return response.json()})
		.then((json) => {
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			let bien = 0;
			preguntas.forEach(pregunta =>{
				pregunta.feedback = json["feedback"][pregunta.id_pregunta];
				pregunta.correcta = json["preguntas"][pregunta.id_pregunta];
				if(json["preguntas"][pregunta.id_pregunta])
					bien++;
			})
			setInsignias(json["insignias"]||[]);
			setIndice(-2);
			setModal(true);
			setCorrectas(bien);
		})
		.catch((error) => {
			console.log(error)
		});
	}
	return(
		<div className={'preguntaDiv '+(indice == -2 ? !pregunta.correcta ? ' incorrecta' : ' correcta' : "")}>
			<div className='opcPregunta'>
				<span>Pregunta número: </span>
				<span><b>{pregunta.orden}</b> de <b>{preguntas.length}</b></span>
				{indice!=-2 &&
					<progress value={(pregunta.orden-1)/preguntas.length || 0}></progress>
				}
			</div>
			{indice!=-2 && error &&
				<div className='error'>{error}</div>
			}
			<div className='pregunta'>
				{imagen.ruta &&
					<VerFile editar={false} imagen={imagen} setImagen={setImagen} tipo={"img"}/>
				}
				<div className='renglon'>
					<div>{pregunta.orden+".-  "}</div>
					<div>{pregunta.pregunta}</div>
				</div>
			</div>
			<div className='respuestas'>
			{
				pregunta.respuestas.map((respuesta, i)=>{
					return(
						<Respuesta respuesta={respuesta} indice={indice} pregunta={pregunta} key={respuesta.id_respuesta}>
						</Respuesta>
					);
				})
			}
			</div>
			{indice == -2 &&
				<div className='feedback'>{pregunta.feedback}</div>
			}
			{indice!=-2 &&
				<div className='botones'>
					<div className='sig' onClick={()=>{
						let novacio = pregunta.respuestas.some(x=>{
							return x.correcta;
						})
						if(!novacio){//no eligió ninguna
							setError("Elige una respuesta para poder continuar.")
							return false;
						}
						if(indice+1==preguntas.length){
							terminar()
							return false;
						}
						setIndice(indice+1);
					}}>{indice+1==preguntas.length ? "Terminar" : "Siguiente"}</div>
				</div>
			}
		</div>
	);
})


const Respuesta = (({respuesta, indice, setBorrarResp, setPreguntas, pregunta})=>{
	const [imagen, setImagen] = useState({id_archivo:respuesta.id_archivo, ruta:respuesta.ruta})
	return(
		<div>
			{imagen.ruta &&
				<VerFile editar={false} imagen={imagen} setImagen={setImagen} tipo={"img"}/>
			}
			<label>
				<input type='radio' name={"correcta-" + pregunta.id_pregunta} disabled={indice==-2} defaultChecked={respuesta.correcta} value={respuesta.id_respuesta} onChange={(e)=>{
					pregunta.respuestas.forEach(respuesta=>{
						respuesta.correcta=false;
					})
					respuesta.correcta = e.target.checked;
				}}/>
				<span>{respuesta.respuesta == "" ? "Selecciona" : respuesta.respuesta}</span>
			</label>
		</div>
	);
})

export default Cuestionario;