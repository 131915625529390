import React, { useState, useEffect, useRef } from 'react';
import useToken from '../../useToken';
import {useParams} from 'react-router-dom';
import imgCargando from '../../img/cargando.gif';
import defaultSubir from '../../img/defualtSubir.png';
import defaultPDF from '../../img/defaultPDF.png';
import defaultArchivo from '../../img/defaultFile.png';


const SubirFile = (({editar, imagen, setImagen, tipo, setPermitir, testimonio})=>{
	const {token, setToken} = useToken();
	const [cargando, setCargando] = useState(false);
	const [modal, setModal] = useState(false);
	const chunk_size = 5*1024*1024;//si pesa menos que esto, subir de manera normal
	useEffect(()=>{
		if(setPermitir)
			setPermitir(cargando);
	}, [cargando])
	const aceptar = {
		"img" : "image/*",
		"video" : "video/*",
		"pdf" : "file/pdf",
	}
	if(testimonio)
		aceptar["multi"] = "video/*, image/*";
	function getExtension(){
		if(imagen.ruta){
			let a = imagen.ruta.split(".");
			return a[a.length-1].toLowerCase();
		}
		else{
			return "";
		}
	}
	function getFormato(ext){
		if(extension  === "jpeg" || extension === "jpg" || extension === "png" || extension === "tiff" || extension === "gif") {
            return "imagen";
        }
        else if(extension  === "mp4" || extension === "m4a"|| extension === "f4v" || extension  === "m4b" || extension  === "mov") {
            return "video";
        }
		else{
			return "archivo";
		}
	}
	const extension = getExtension();
	const formato = getFormato(extension);
	const {id} = useParams();
	function cambiaImg(e){
		e.currentTarget.querySelector("input[type=file]").click();
	}
	function subirImg(e) {
		setCargando(true);
		let input = e.currentTarget;
		if(!input.files.length)
			return false;
		let size = input.files[0].size;
		if(size <= chunk_size){
			let data = new FormData();
			data.append('imagen', input.files[0]);
			data.append('tipo', tipo);
			data.append('id_curso', id);
			fetch('/cursos/clienteAPI/subirImagen.php?token='+token, {
			  method: 'POST',
			  body: data
			}).then((response) => {/*console.log(response.text());*/return response.json()})
			.then((json) => {
				if(json["errAuth"]){
					setToken({})
					return false;
				}
				setCargando(false);
				setImagen({id_archivo: json["id_archivo"], ruta: json["ruta"]});
			})
			.catch((error) => {
				console.log(error);
			});
		}
		else{
			let i = 0;
			let num_chunks = Math.ceil(size/chunk_size);
			let folder = "folder"+Date.now();
			let ext = input.files[0].name.split('.').pop();
			uploadChunk(i);
			function uploadChunk(i){
				let chunk = input.files[0].slice(i*chunk_size, (i+1)*chunk_size);
				let data = new FormData();
				data.append('chunk', chunk);
				data.append('parte', i);
				data.append('folder', folder);
				if(i == num_chunks-1){
					data.append('ext', ext);
					data.append('num_chunks', num_chunks);
					data.append('tipo', tipo);
					data.append('final', true);
				}
				data.forEach((x,y)=>{
				})
				fetch('/cursos/clienteAPI/subirChunk.php?token='+token, {
				  method: 'POST',
				  body: data
				}).then((response) => {/*console.log(response.text());*/return response.json()})
				.then((json) => {
					if(json["errAuth"]){
						setToken({})
						return false;
					}
					if(i < num_chunks-1)
						uploadChunk(i+1);
					else{
						setCargando(false);
						setImagen({id_archivo: json["id_archivo"], ruta: json["ruta"]});
					}
				})
				.catch((error) => {
					console.log(error);
				});

			}
		}
	}
	useEffect(()=>{
		if(modal){
			document.body.addEventListener("keydown", cerrar)
		}
		else{
			document.body.removeEventListener("keydown", cerrar)
		}
	}, [modal])

	function cerrar(e){
		if(e.code=="Escape"){
			if(document.activeElement.tagName != "INPUT" && document.activeElement.tagName != "TEXTAREA"){
				setModal(false);
			}
		}
	}
	return(
		<>
		{modal &&
			<div className='modalFile' onClick={(e)=>{
					if(e.target===e.currentTarget){
						setModal(false);
					}
				}}>
				{(tipo == "img" || formato=="imagen") &&
					<img src={"/cursos/clienteAPI/"+tipo+"/"+imagen.ruta}/>
				}
				{tipo != "img" && formato=="archivo" &&
					<iframe src={"https://docs.google.com/viewer?url=/cursos/clienteAPI/"+tipo+"/"+imagen.ruta+"&embedded=true"} frameBorder="0"></iframe>
				}
			</div>
		}
		<div className={'divImg' + (editar ? " editarImg" : "")} onClick={(e)=>{
			if(editar){
				cambiaImg(e);
			}
			else{
				if(tipo!="video")
					setModal(true);
			}
		}}>
		{cargando && 
			<img src={imgCargando}/>
		}
		{!cargando && !imagen.ruta &&
			<img src={defaultSubir}/>
		}
		{!cargando && imagen.ruta && formato=="imagen" &&
			<img src={"/cursos/clienteAPI/"+tipo+"/"+imagen.ruta}/>
		}
		{!cargando && imagen.ruta && formato=="archivo" &&
			<img src={defaultArchivo}/>
		}
		{!cargando && imagen.ruta && formato=="video" &&
			<video src={"/cursos/clienteAPI/"+tipo+"/"+imagen.ruta+"#t=0.001"} controls></video>		
		}
			<input type='file' onChange={subirImg} accept={aceptar[tipo]}/>
		</div>
		</>
	);
});

export default SubirFile;