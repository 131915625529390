import React, { useState, useEffect, useRef, useContext } from 'react';
import {useNavigate} from 'react-router-dom';
import LoginContext from '../Login/LoginContext';
import './Cambiar.css';


const Cambiar = ({setModal})=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [cambio, setCambio] = useState("");
	const actual = useRef();
	const nueva = useRef();
	const confirma = useRef();
	function cambiarContra(){
		if(!actual.current.reportValidity() || !nueva.current.reportValidity() || !confirma.current.reportValidity())
			return false
		if(nueva.current.value!=confirma.current.value){
			return false;
		}
		let datos = {actual:actual.current.value, nueva: nueva.current.value};
	    fetch("/cursos/clienteAPI/Registro/cambiar.php?token="+token, {
	        method: 'POST',
	        mode: 'cors',
	        headers: {
	          Accept: 'application/json',
	          'Content-Type': 'application/json',
	          'Access-Control-Allow-Origin':'*'
	        },
	        body: JSON.stringify(datos)
	      })
	    .then(response=> response.json())
	    .then(json=>{
	    	if(json["errAuth"]){
				setToken({})
				return false;
			}
		    setCambio(json["status"])
		    if(json["status"]){
			    setTimeout(function(){
			    	setModal(false);
			    }, 4000)
		    }
	    })
	}
	return(
		<>
			<div className='modal' onClick={(e)=>{
				if(e.target===e.currentTarget){
					setModal(false);
				}
			}}>
				<div className="modCambiar">
					{cambio &&
						<div>La contraseña ha sido cambiada con éxito.</div>
					}
					{(cambio === "" || !cambio) &&
						<>
							<div>
								<div>Contraseña Actual</div>
								<div><input className="inputDon" placeholder="Contraseña Actual" type='password' ref={actual} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required/></div>
							</div>
		              		<div>Debe incluir al menos una letra mayuscula, una minúscula y un número. Al menos 8 caracteres</div>
							<div>
								<div>Nueva Contraseña</div>
								<div><input className="inputDon" placeholder="Contraseña Nueva" type='password' ref={nueva} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required/></div>
							</div>
							<div>
								<div>Confirmar Nueva Contraseña</div>
								<div><input className="inputDon" placeholder="Confirmar Contraseña" type='password' ref={confirma} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required/></div>
							</div>
							<div className='botones'>
								<div className='sig' onClick={()=>{
									cambiarContra()
								}}>Cambiar contraseña</div>
							</div>
							{cambio!=="" &&
								<div>Hubo un error al cambiar la contraseña, por favor confirma que los datos introducidos sean correctos.</div>
							}
						</>
					}
				</div>
			</div>
		</>
	);
}

export default Cambiar;