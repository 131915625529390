import React, { useEffect, useState } from 'react';
import useToken from '../../useToken';
import './Notificaciones.css';

const Notificaciones = () => {
    const {token, setToken} = useToken();
    const [notificaciones, setNotificaciones] = useState([]);

    useEffect(() => {

        const fetchNotificaciones = async () => {
            try {
            const response = await fetch('https://www.datamast3r.com/cursos/clienteAPI/Push/get.php?token='+token);
            const data = await response.json();
            setNotificaciones(data["notificaciones"] || []);
            } catch (error) {
            console.error('Error fetching notifications:', error);
            }
        };
        fetchNotificaciones();
    }, []);

    return (
        <div className="notificaciones">
            <h2>Notificaciones</h2>
            {notificaciones.length === 0 &&
                <p>No hay notificaciones</p>
            }
            {notificaciones.length > 0 &&
                <div>
                    {notificaciones?.map((notificacion) => (
                        <div key={notificacion.id} className={notificacion.status === 0 ? 'nueva' : 'leida'}>
                            <a href={notificacion.link}>
                                <div className='imgNotificacion'>
                                    {notificacion.imagen &&
                                        <img src={notificacion?.imagen}/>
                                    }
                                </div>
                                <div>
                                    <h4>{notificacion.titulo}</h4>
                                    <p>{notificacion.mensaje}</p>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};

export default Notificaciones;