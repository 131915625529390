import React, { useState, useEffect, useRef, useContext } from 'react';
import {useNavigate} from 'react-router-dom';
import LoginContext from '../Login/LoginContext';
import SubirFile from '../SubirFile/SubirFile';
import Cambiar from '../Cambiar/Cambiar';
import './Perfil.css';
import { unsubscribe, deviceToken } from '../../push-notification';

const Perfil = ({setModo, modo, notifica})=>{
	const iOS = false ///iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [modal, setModal] = useState(false)
	const [imagen, setImagen] = useState({id_archivo:0, ruta:""})
	const [imagen1, setImagen1] = useState({id_archivo:0, ruta:""})
	const [imagen2, setImagen2] = useState({id_archivo:0, ruta:""})
	const [imagen3, setImagen3] = useState({id_archivo:0, ruta:""})
	const [imagen4, setImagen4] = useState({id_archivo:0, ruta:""})
	const [imagen5, setImagen5] = useState({id_archivo:0, ruta:""})
	const [imagen6, setImagen6] = useState({id_archivo:0, ruta:""})
	const [editar, setEditar] = useState(false)
	const [evalua, setEvalua] = useState("inicial")
	const [datos, setDatos] = useState({})
	const [activas, setActivas] = useState(!iOS && Notification?.permission=="granted" && datos?.status=="true")
	const navigate = useNavigate();

	useEffect(()=>{
		if(token==""){
			//eliminar suscripción y tokens de este usuario.
			//unsubscribe(); logout, sirve que borramos el token token
			navigate('/login', {replace:true});
		}
		getData();
	}, [])
	useEffect(()=>{
		if(modal){
			document.body.addEventListener("keydown", cerrar)
		}
		else{
			document.body.removeEventListener("keydown", cerrar)
		}
	}, [modal])

	function cerrar(e){
		if(e.code=="Escape"){
			if(document.activeElement.tagName != "INPUT" && document.activeElement.tagName != "TEXTAREA"){
				setModal(false);
			}
		}
	}
	function cancelar(){
		setModal(false)
	}

	async function logout(){
		let dt = await deviceToken();
		let userToken = "";
		if(dt){
			userToken = "&userToken="+dt;
		}
		fetch("/cursos/clienteAPI/logout.php?token="+token+userToken)
		.then(response=> response.json())
		.then(json=>{
			setToken({estado:'cerrar'});
		});
	}

	async function getData(){
		let dt = await deviceToken();
		let userToken = "";
		if(dt){
			userToken = "&userToken="+dt;
		}
		const data = await fetch("/cursos/clienteAPI/Perfil/get.php?token="+token+userToken)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		setDatos(data || {});
		setImagen({id_archivo: data.id_archivo, ruta : data.ruta});
		setImagen1({id_archivo: data.id_archivo1, ruta : data.ruta1});
		setImagen2({id_archivo: data.id_archivo2, ruta : data.ruta2});
		setImagen3({id_archivo: data.id_archivo3, ruta : data.ruta3});
		setImagen4({id_archivo: data.id_archivo4, ruta : data.ruta4});
		setImagen5({id_archivo: data.id_archivo5, ruta : data.ruta5});
		setImagen6({id_archivo: data.id_archivo6, ruta : data.ruta6});
		setActivas(!iOS && Notification?.permission=="granted" && data.status=="true");
	}

	function guardar(){
		let data = {...datos, 
			id_archivo : imagen.id_archivo,
			id_archivo1 : imagen1.id_archivo,
			id_archivo2 : imagen2.id_archivo,
			id_archivo3 : imagen3.id_archivo,
			id_archivo4 : imagen4.id_archivo,
			id_archivo5 : imagen5.id_archivo,
			id_archivo6 : imagen6.id_archivo
		};
		fetch("/cursos/clienteAPI/Perfil/set.php?token="+token, {
	      method: 'POST',
	      mode: 'cors',
	      headers: {
	        Accept: 'application/json',
	        'Content-Type': 'application/json',
	        'Access-Control-Allow-Origin':'*'
	      },
	      body: JSON.stringify(data)
	    })
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			setEditar(false);
		})
	}
	function changeThemeColor(color) {
	    var metaThemeColor = document.querySelector("meta[name=theme-color]");
	    metaThemeColor.setAttribute("content", color);
	}
	return (
		<div className='perfil'>
			{modal &&
				<Cambiar setModal={setModal}/>
			}
			{/*<div className='editar botones'>
				{!editar &&
					<div className='sig' onClick={()=>{setEditar(true)}}>
						Editar
					</div>
				}
				{editar &&
					<div className='sig' onClick={guardar}>
						Guardar
					</div>
				}			
			</div>Por si no convence*/}
			<div className="perfilWeb">
				<div className='informacion'>
					<div className="titGuardar">	
						<div className="titTexto">Datos de Contacto</div>
						<div className='editar botones'>
							{!editar &&
								<div className='sigLetra' onClick={()=>{setEditar(true)}}>
									Editar
								</div>
							}
							{editar &&
								<div className='sigLetra' onClick={guardar}>
									Guardar
								</div>
							}			
						</div>
					</div>
					<div className='pfp'>
						<div>
							<SubirFile editar={editar} tipo='img' setImagen={setImagen} imagen={imagen} root={false}/>
						</div>
					</div>
					<div>
						<div className='datosContacto'>
							<div>
								<span>Nombre</span>
								{!editar &&
									<span>{datos.nombre != null ? datos.nombre : <span className="primerDato">Falta dato</span>}</span>
								}
								{editar &&
									<input type="text" placeholder="Añade de nombre" defaultValue={datos.nombre} onChange={(e)=>{
										datos.nombre = e.target.value;
									}}/>
								}
							</div>
							<div>
								<span>Correo</span>
								{!editar &&
									<span>{datos.correo != null ? datos.correo : <span className="primerDato">Falta dato</span>}</span>
								}
								{editar &&
									<input type="text" placeholder="Añade tu correo" defaultValue={datos.correo} onChange={(e)=>{
										datos.correo = e.target.value;
									}}/>
								}
							</div>
							<div>
								<span>Teléfono</span>
								{!editar &&
									<span>{datos.telefono != null && datos.telefono !="" ? datos.telefono : <span className="primerDato">Falta dato</span>}</span>
								}
								{editar &&
									<input type="text" placeholder="Añade tu teléfono" defaultValue={datos.telefono} onChange={(e)=>{
										datos.telefono = e.target.value;
									}}/>
								}
							</div>
							<div>
								<span>Ocupación</span>
								{!editar &&
									<span>{datos.ocupacion != null && datos.ocupacion !="" ? datos.ocupacion : <span className="primerDato">Falta dato</span>}</span>
								}
								{editar &&
									<input type="text" placeholder="Añade tu ocupación" defaultValue={datos.ocupacion} onChange={(e)=>{
										datos.ocupacion = e.target.value;
									}}/>
								}
							</div>
							<div className='botones'>
								<div className='cancel' onClick={()=>{
									setModal(true)
								}}>Cambiar contraseña</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="burbujaPerfil">
				<div className="titTexto">Ajustes</div>
				<div className='ajustes'>
					<div>
						<span>Modo oscuro</span>
						<span>
							<label>
								<input type='checkbox' defaultChecked={modo=="oscuro"} onChange={(e)=>{
									setModo(e.target.checked ? 'oscuro' : 'claro');
									changeThemeColor(e.target.checked ? '#181818' : 'rgb(246, 246, 242)');
								}}/>
								<span className='switch'>
									<span className='bola'></span>
								</span>
							</label>
						</span>
					</div>
					<div>
						<span>Recibir notificaciones</span>
						<span>
							<label>
								<input type='checkbox' checked={activas} onChange={(e)=>{
									if (!'PushManager' in window){return false;}
									if (!'serviceWorker' in navigator){return false;}
									if (!'Notification' in window){return false;}
									if(token!=""){
										if(e.target.checked){
											notifica(e.target);
											datos.status='true'
											setActivas(!iOS && Notification?.permission=="granted" && datos.status=="true")
										}
										else{
											unsubscribe();
											datos.status='false'
											setActivas(!iOS && Notification?.permission=="granted" && datos.status=="true")
										}
									}
									}}/>
								<span className='switch'>
									<span className='bola'></span>
								</span>
							</label>
						</span>
					</div>
					<div className='botones'>
						<div className='cancel' onClick={logout}>Cerrar sesión</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Perfil;